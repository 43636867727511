import ekeyRedirectDomains from '@/js/json/ekeyRedirectDomains.json';
import { useApiStore } from '@/js/stores/apiStore.js';
import { useAuthStore } from '@/js/stores/authStore.js';
import { createRouter, createWebHistory } from 'vue-router';
import generateRoutes from './routes';

const router = createRouter({
    history: createWebHistory(),
    routes: generateRoutes(),
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
    },
});

const checkEmailDomain = email => {
    return ekeyRedirectDomains.domains.some(domain =>
        email.toLowerCase().endsWith(domain),
    );
};

router.beforeEach(async (to, from, next) => {
    // Reset errors before each route change
    useApiStore().resetErrors();

    // Si la page vient d'être rechargée, on vérifie si l'utilisateur est connecté
    if (!useAuthStore().isInitialized) {
        await useAuthStore().init();
    }

    // Vérifier si l'utilisateur est connecté et a une extension email interdite
    if (
        useAuthStore().isAuth &&
        useAuthStore().user?.email &&
        checkEmailDomain(useAuthStore().user.email) &&
        to.name !== 'ekey-redirect'
    ) {
        return next({
            name: 'ekey-redirect',
            params: { lang: to.params.lang || 'fr' },
        });
    }

    // Check if user has the permissions to access the route
    const permissions = to.meta.permissions;
    const access = to.meta.access;
    if (access) {
        const companyAccess =
            useAuthStore()?.user?.company?.conditions[0]?.access.split(',') ??
            [];
        if (!companyAccess.includes(access)) {
            return next({
                name: '403',
                params: { lang: to.params.lang || 'fr' },
            });
        } else {
            return next();
        }
    }
    if (permissions) {
        const userPermissions =
            useAuthStore()?.user?.permission?.split(',') ?? [];
        if (
            !permissions.some(permission =>
                userPermissions.includes(permission),
            )
        ) {
            return next({
                name: '403',
                params: { lang: to.params.lang || 'fr' },
            });
        } else {
            return next();
        }
    } else {
        return next();
    }
});

router.afterEach(to => {
    window.tidioChatApi && window.tidioChatApi.display(to.meta.showChat);
});

export default router;
